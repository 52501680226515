import { defineStore } from 'pinia'
import type {
  Currency,
  CustomerGroup,
  CustomerGroupPagedResult,
} from '~/types/api'
import type { RunningPromises } from '~/types/store'
import type { AutocompleteItem } from '~/types/form'
import { APIFilters } from '~/utils/APIFilters'
import type { QueryParams } from '~/types/api'

export const useCustomerGroups = defineStore('customerGroups', () => {
  const customerGroupsRepository = createCustomerGroupsRepository()

  const { apiAlert } = useAlert()
  const { loadList } = useCache()

  const fetchedCustomerGroups = ref<CustomerGroup[]>([])
  const customerGroupsLoading = ref(false)

  const customerGroups = computed(() => {
    return fetchedCustomerGroups.value.map(
      (customerGroup: CustomerGroup) =>
        ({
          value: customerGroup.nid,
          text: customerGroup.title,
        }) as AutocompleteItem
    )
  })

  const customerGroupsPromise = ref<Promise<CustomerGroup[]> | null>(null)
  function loadCustomerGroups(): Promise<CustomerGroup[]> {
    const params = {
      sort: APIFilters.makeSort({ nid: 'ASC' }),
    } as QueryParams
    const apiCall = customerGroupsRepository.getAll.bind(
      customerGroupsRepository,
      params
    )

    return loadList<CustomerGroup, CustomerGroupPagedResult>(
      fetchedCustomerGroups,
      customerGroupsPromise,
      customerGroupsLoading,
      apiCall
    )
  }

  type Currencies = {
    [customerGroupId: number]: Currency
  }
  const fetchedCurrencies = ref<Currencies>({})
  // cannot return promise as part of state so have to keep them in separate object
  const runningPromises = ref<RunningPromises<Currency>>({})

  function getCurrencyForCustomerGroup(customerGroupId: number) {
    if (fetchedCurrencies.value[customerGroupId] !== undefined) {
      return Promise.resolve(fetchedCurrencies.value[customerGroupId])
    }

    if (runningPromises.value[customerGroupId] !== undefined) {
      return runningPromises.value[customerGroupId]
    }

    const promise = customerGroupsRepository
      .getCurrencyForCustomerGroup(customerGroupId)
      .then((result: Ref<Currency>) => {
        fetchedCurrencies.value[customerGroupId] = result.value
        return result.value
      })
      .catch(apiAlert)
    runningPromises.value[customerGroupId] = promise
    return promise
  }

  return {
    fetchedCustomerGroups,
    customerGroupsLoading,
    customerGroups,
    loadCustomerGroups,
    fetchedCurrencies,
    getCurrencyForCustomerGroup,
  }
})
